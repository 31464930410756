@import "../../styles/_variables.scss";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;
    background-color: $primary-color;
    border-radius: 10px;
    
    &:first-child {
      flex: 1;
      margin-right: $margin-md;
      background-color: $black;
    }

    &:last-child {
      min-width: 60px;
    }

    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      color: $white;
      font-size: 30px;
      max-width: 83px;
      width: 100%;
      height: 83px;
      a { 
        color: $white;
        font-size: 30px;
      }
    }
  }
}