@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
    word-wrap: break-word; /* Permet de renvoyer le texte à la ligne */
    white-space: normal; /* Assure que le texte puisse se renvoyer à la ligne */
    margin-bottom: 8px; /* Ajoute un espace entre le label et l'input */
  }

  &:not(.inline) {
    label+input {
      margin-top: 5px;
    }
  }
  
  &.inline {
    @include inline;
  }
  
  input{
    @include input();
  }
  &.primary {
    @include primary;
  }
  &.secondary {
    @include secondary;
  }
  &.login {
    @include login;
  }
  
}
