@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modalContent{
    background-color: $bg-color;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 200px;
    max-width: 500px;
    position: absolute;
    bottom:0;
}

.modalHeader{
    display: flex;
    flex-direction: column;
    align-items: center;

    h3{
        margin-bottom: 0;
        margin-top: 0;
    }

    span{
        color: $text-light;
        font-size: $regular;
    }
}

.modalContenu{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.contenu{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 10px;

    p{
        color: $text-light;
        font-size: $regular;
    }

    span{
        color: $text-color;
        font-size: $regular;
    }
}