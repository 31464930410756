@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

li{
    list-style: none;
}

ul{
    padding: 0;
}

.page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;

}

.contact{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: auto;
    background-color: #F9FAFF;
    margin: 10px 20px;
    padding-left:20px;
    max-width: 1024px;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bottom{
        height: 100%;
        align-content: flex-end;
        width: 100%;
    }
}

.insideListe{
    display: flex;

    .leftsection{
        margin-right: 10px;
    }

    .rightSection{
        display: flex;
        flex-direction: column;

        .name{
            font-size: 1rem;
            font-weight: bold;
        }

        .infos{
            font-size: 0.85rem;
            color: $grey;
        }
    }
}

.borderTrait{
    border-bottom: 1px solid $light-grey;
    margin: 10px 0;
    width: 100px;
    height: 1px;
}