@import "./_variables.scss";
@import "./_mixins.scss";


html,
body,
#root {
  height: 100%;
  min-height: 100%;
  background-color: $primary-color;
}

:focus-visible {
  outline: none;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-dropzone: transparent;
  overflow: hidden;
}

.App {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background-color: $bg-color;
}

#modal {
  position: absolute;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 { color: $text-color }

h1 {
  font-family: "Poppins-Bold", sans-serif;
  font-weight: normal;
  color: $text-color;
}

h3,
h4 {
  font-family: "Poppins-SemiBold", sans-serif;
  font-weight: normal;
  color: $text-color;
}

h1 {
  font-size: $h1;
}

h2 {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $regular;
}

h5 {
  font-family: "Poppins-Bold", sans-serif;
  font-weight: normal;
  font-size: $regular;
  margin-bottom: $margin-sm;
  color: $text-color;
}

p,
a,
li,
input,
textarea {
  font-family: "Poppins", sans-serif;
  color: $text-color;
  font-size: $regular;
  &.bold {
    font-family: "Poppins-SemiBold", sans-serif;
  }
  &.small {
    font-size: $regular;
  }
}

p.error {
  color: $warn;
}

p {
  margin-top: 0;
}

a {
  text-underline-offset: 2px;
  @include remove-tap-highlight();
}

button {
  align-items: center;
  justify-content: center;
  font-family: "Poppins-Medium", sans-serif;
  font-size: $button;
  background-color: $primary-color;
  padding: 16px 20px;
  color: $white;
  border: solid 1px $primary-color;
  border-radius: $radius;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    top: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader > div {
      position: initial;
    }

    svg {
      position: relative;
      top: -50%;
      vertical-align: middle;
      color: $white;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 10px;
  }

  &.secondary {
    background-color: $secondary-color;
    border: solid 1px $secondary-color;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.grey {
    background-color: #BCC2C5;
    border: solid 1px #BCC2C5;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.black {
    background-color: $black;
    border: solid 1px $black;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.light {
    background-color: rgba(113, 122, 151, 0.04);
    border: solid 1px rgba(113, 122, 151, 0.04);
    color: $text-color;
  }

  &.third {
    background-color: $white;
    border: solid 1px $text-color;
    color: $text-color;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &.dashed {
    background-color: rgba(#96A5B8, 0.2);
    border: 1px dashed rgba($text-color, 0.2);
    color: $text-color;
    svg {
      color: $text-color;
    }
    >span {
      text-decoration: underline;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-on-mobile {
  display: none;
  @media screen and (min-width: $sm-screen) {
    display: block;
  }
}

.only-on-mobile {
  display: block;
  @media screen and (min-width: $sm-screen) {
    display: none;
  }
}
